<template>
  <div>
    <v-row v-show="hasCustomer">
      <v-col class="d-flex justify-space-start">
        <app-file-field
          ref="AppFileField"
          btnIcon="file_upload"
          color="primary"
          large
          accept=".xlsx"
          url="customer/import-xlsx/parse"
          @input="handleImportFile($event)"
        />
        <v-btn
          @click="handleStoreImport()"
          class="ml-10"
          color="primary"
          outlined
        >
          Importar
        </v-btn>
      </v-col>
    </v-row>

    <template v-if="!hasCustomer">
      <v-row>
        <v-col
          class="d-flex flex-column align-center mt-10"
          cols="12"
          md="4"
          offset-md="4"
        >
          <div>
            <v-avatar size="150px" color="#E6F2E6">
              <v-icon size="130px" color="success">mdi-file-excel</v-icon>
            </v-avatar>
          </div>
          <div class="text-center">
            <h2 class="mt-5">Importe seus {{ customerAlias }}s!</h2>
            <br />
            Faça a importação do seus {{ customerAlias }}s por meio de uma
            planilha.
          </div>

          <div class="text-center mt-5">
            <v-btn @click="$refs.AppFileField.handleXmlClick()" color="primary">
              <app-icon class="mr-2">file_upload</app-icon>
              Carregar arquivo
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center mt-10">
        <v-col cols="12" md="6">
          <p>
            <b>Passo 1:</b>
            Faça <a @click="downloadImportModel()">download do nosso modelo</a>
            do arquivo de importação;
          </p>

          <p>
            <b>Passo 2: </b> Adapte seus clientes nesse modelo, seguindo o
            padrão de cada campo;
          </p>

          <p><b>Passo 3: </b>Clique em 'Carregar arquivo';</p>
          <p><b>Passo 4: </b>Clique em 'Importar';</p>
          <br />
          <h4>Algumas observações importantes</h4>

          <ul>
            <li class="mt-2">
              Os campos no arquivo precisam estar de acordo com o modelo. Então
              é muito importante analisar esse padrão antes de importar. Caso
              tenha dúvidas importe um arquivo com poucos clientes, depois
              importe o restante.
            </li>

            <li class="mt-2">O único campo obrigatório é o 'Nome'.</li>
            <li class="mt-2">
              No campo Gênero use
              <br />
              f > Para Feminino ,<br />
              m > Para Masculino, <br />
              o > Para Outros.
            </li>
          </ul>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col>
        <v-data-table
          v-if="hasCustomer"
          :items="customers"
          :headers="headers"
          disable-sort
          dense
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:[`item.genre`]="{ item }">
            {{ genre[item.genre] }}
          </template>
          <template v-slot:[`item.cpf`]="{ item }">
            {{ $format.cpf(item.cpf) }}
          </template>
          <template v-slot:[`item.birth_date`]="{ item }">
            {{ $format.dateBr(item.birth_date) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  data() {
    return {
      e6: 1,

      customers: [],
      genre: {
        female: "Feminino",
        male: "Masculino",
        others: "Outro",
      },

      headers: [
        { align: "center", text: "Nome", value: "name" },
        { align: "center", text: "CPF", value: "cpf" },
        { align: "center", text: "RG", value: "rg" },
        { align: "center", text: "WhatsApp", value: "phone_wa" },
        { align: "center", text: "Email", value: "email" },
        { align: "center", text: "Gênero", value: "genre" },
        { align: "center", text: "Estado civil", value: "civil_status" },
        { align: "center", text: "Nacionalidade", value: "nationality" },
        { align: "center", text: "Escolaridade", value: "scholarity" },
        { align: "center", text: "Ocupação", value: "occupation" },
        {
          align: "center",
          text: "Valor por sessão",
          value: "value_per_session",
        },
        { align: "center", text: "Dia de cobrança", value: "collection_day" },
        {
          align: "center",
          text: "Nome responsável financeiro",
          value: "financial_responsible_name",
        },
        {
          align: "center",
          text: "WhatsApp responsável financeiro",
          value: "financial_responsible_whatsapp",
        },
        {
          align: "center",
          text: "CPF responsável financeiro",
          value: "financial_responsible_cpf",
        },
        {
          align: "center",
          text: "RG responsável financeiro",
          value: "financial_responsible_rg",
        },
        {
          align: "center",
          text: "Email responsável financeiro",
          value: "financial_responsible_email",
        },
        {
          align: "center",
          text: "Nome contato de emergência",
          value: "emergency_contact_name",
        },
        {
          align: "center",
          text: "WhatsApp contato de emergência",
          value: "emergency_contact_whatsapp",
        },
        { align: "center", text: "Data de nascimento", value: "birth_date" },
        { align: "center", text: "Observações", value: "details" },
        { align: "center", text: "Encaminhamento", value: "forwarding" },
        { align: "center", text: "Endereço", value: "address" },
        { align: "center", text: "Número", value: "number" },
        { align: "center", text: "Bairro", value: "district" },
        { align: "center", text: "Cidade", value: "city" },
        { align: "center", text: "Estado", value: "state" },
        { align: "center", text: "Complemento", value: "complement" },
        { align: "center", text: "CEP", value: "postal_code" },
      ],
    };
  },
  computed: {
    hasCustomer() {
      return this.customers.length > 0;
    },
    customerAlias() {
      return customerAlias();
    },
  },

  methods: {
    handleImportFile(data) {
      this.customers = data;
    },
    handleStoreImport() {
      this.$loading.start();
      this.$http
        .store("customer/import-xlsx/store", {
          customers: this.customers,
        })
        .then((response) => {
          this.$loading.finish();
          this.$store.commit("onboarding/customerStored");
          this.$snackbar({
            message: "Importação realizada com sucesso!",
            color: "success",
            timeout: 3000,
            showClose: true,
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    downloadImportModel() {
      this.$loading.start();

      this.$http
        .download("customer/import-xlsx/download")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PlanilhaModeloParaImportação.xlsx");
          document.body.appendChild(link);
          link.click();
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.v-stepper
  box-shadow: none
</style>